const getSiteLocationsBySiteId = async (siteId) => {
    let result = null
    try {
      result = await window.axios.get(`/v1/sitelocation/SiteLocations/${siteId}`)
      let list = result.data.data;
      return list;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
  };

const getSites = async () => {
  let result = null
  try {
    result = await window.axios.get('/v1/sites/portfolio')
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const createSiteLocation = async (siteLocationResourceModel) => {
  try {
    var result = await window.axios.post('/v1/sitelocation/create', siteLocationResourceModel)
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const updateSiteLocation = async (siteLocationResourceModel) => {
  try {
    var result = await window.axios.put('/v1/sitelocation/update', siteLocationResourceModel)
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const deleteSiteLocation = async (siteLocationId) => {
  try {
  var result = await window.axios.delete(`/v1/sitelocation/delete/${siteLocationId}`)
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

export default {
    getSiteLocationsBySiteId,
    getSites,
    createSiteLocation,
    updateSiteLocation,
    deleteSiteLocation
}